<template>
  <v-divider vertical class="mx-1 my-1" />
</template>

<script>
export default {
  name: 'VerticalDivider'
};
</script>

<style scoped>
hr.v-divider {
  border-color: var(--c-grey);
  border-width: 1px;
}
</style>

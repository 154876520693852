<template>
  <div class="grey-background">
    <div class="page__container">
      <h1 class="text-center mb-10">{{ $t('onboarding.chooseProduct') }}</h1>
      <v-row justify="center" align="stretch">
        <v-col v-for="(item, index) in productGroup" :key="index" cols="auto">
          <v-card class="product-card">
            <v-card-title class="product-card__title gradient-diagonal--primary text-h3">
              {{ item.productName }}
            </v-card-title>

            <v-card-text class="product-card__text">
              {{ $t('onboarding.selectInfo', { productName: item.productName }) }}
              <v-spacer />
              <v-row class="partner-logos__row">
                <v-col class="partner-logos__col" cols="auto">
                  <img :src="item.logo" v-bind="getLogoProps(item)" />
                </v-col>
                <vertical-divider v-if="item.partnerLogo" class="partner-logos__divider" />
                <v-col class="partner-logos__col" cols="auto">
                  <img :src="item.partnerLogo" v-bind="getLogoProps(item)" />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="flex-column">
              <v-row dense style="width: 100%">
                <v-col cols="12">
                  <app-btn
                    :id="item.actions[0].id"
                    block
                    :title="item.actions[0].text"
                    @click="item.actions[0].action"
                  />
                </v-col>
                <v-col cols="12">
                  <app-btn
                    :id="item.actions[1].id"
                    outlined
                    block
                    :title="item.actions[1].text"
                    @click="item.actions[1].action"
                  />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VerticalDivider from '@/shared/CreateCreditor/VerticalDivider';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';

export default {
  name: 'LandingPage',

  mixins: [OnboardingProductMixin],

  components: {
    VerticalDivider
  },

  methods: {
    pushToRoute(name, type) {
      this.$router.push({
        name: name,
        params: { type: type },
        query: { token: this.$route.query.token }
      });
    },
    getLogoProps(item) {
      return item.scaleLogosByWidth ? { width: '45px' } : { height: '45px' };
    }
  }
};
</script>

<style scoped lang="scss">
.page__container {
  max-width: 1400px !important;
}

.product-card {
  max-width: 380px;
  padding: 8px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__title {
    min-height: 100px;
    font-weight: var(--font-weight-semi-bold);
    justify-content: center;
    color: var(--c-background-text);
    word-break: break-word;
    hyphens: auto;
  }

  &__text {
    padding-top: 24px;
    padding-inline: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

.partner-logos {
  &__row {
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex: 0 0 auto;

    img {
      display: inline-block;
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: auto;
    flex-shrink: 1;
    flex-grow: 0;
    max-width: 50%;
  }

  &__divider {
    min-height: auto;
  }
}
</style>
